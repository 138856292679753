import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { trpc } from '@/lib/trpc/trpc';
import { useToast } from '@/hooks/use-toast';
import { buttonVariants } from '@/components/ui/button';
interface DeleteCalendarBookingProps {
  id: string;
  show: boolean;
  setShow: (open: boolean) => void;
  parentSetShow?: (open: boolean) => void;
}
export default function DeleteCalendarBookingAlert({
  ...props
}: DeleteCalendarBookingProps) {
  const {
    toast
  } = useToast();
  const trpcUtils = trpc.useUtils();
  const deleteCalendarBooking = trpc.Booking.DeleteBooking.useMutation({
    onSuccess: () => {
      toast({
        title: 'Booking Deleted',
        description: 'Successfully deleted booking'
      });
      trpcUtils.Booking.invalidate();
      props.setShow(false);
      if (props.parentSetShow) {
        props.parentSetShow(false);
      }
    },
    onError: error => {
      toast({
        title: 'Error Deleting Booking',
        description: error.message,
        variant: 'destructive'
      });
      trpcUtils.Booking.invalidate();
      props.setShow(false);
    }
  });
  const confirmHandler = (id: string) => {
    deleteCalendarBooking.mutate({
      bookingId: id
    });
  };
  return <AlertDialog open={props.show} onOpenChange={props.setShow} data-sentry-element="AlertDialog" data-sentry-component="DeleteCalendarBookingAlert" data-sentry-source-file="deleteCalendarBooking.tsx">
      <AlertDialogContent data-sentry-element="AlertDialogContent" data-sentry-source-file="deleteCalendarBooking.tsx">
        <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="deleteCalendarBooking.tsx">
          <AlertDialogTitle data-sentry-element="AlertDialogTitle" data-sentry-source-file="deleteCalendarBooking.tsx">Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription data-sentry-element="AlertDialogDescription" data-sentry-source-file="deleteCalendarBooking.tsx">
            This action cannot be undone. Clicking confirm permanently delete
            this booking.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter data-sentry-element="AlertDialogFooter" data-sentry-source-file="deleteCalendarBooking.tsx">
          <AlertDialogCancel data-sentry-element="AlertDialogCancel" data-sentry-source-file="deleteCalendarBooking.tsx">Cancel</AlertDialogCancel>
          <AlertDialogAction className={buttonVariants({
          variant: 'destructive'
        })} onClick={() => confirmHandler(props.id)} data-sentry-element="AlertDialogAction" data-sentry-source-file="deleteCalendarBooking.tsx">
            Confirm
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
}