import { trpc } from '@/lib/trpc/trpc';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useToast } from '@/hooks/use-toast';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { CalendarPicker } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import UserPicker from '@/components/elements/userPicker/userPicker';
interface CalendarBookingFormProps {
  bookingId?: string;
  userId?: string;
  date?: Date | undefined | null;
  bookingStart?: Date | undefined | null;
  bookingEnd?: Date | undefined | null;
  comment?: string;
  parentShow?: (open: boolean) => void;
}
export default function CalendarBookingForm({
  ...props
}: CalendarBookingFormProps) {
  const trpcUtils = trpc.useUtils();
  const {
    toast
  } = useToast();
  const addCalendarBooking = trpc.Booking.CreateBooking.useMutation({
    onSuccess: async () => {
      toast({
        title: 'Booking Added Successfully',
        description: 'Successfully created booking'
      });
      await trpcUtils.Booking.invalidate();
      if (props.parentShow) {
        props.parentShow(false);
      }
    },
    onError: async error => {
      toast({
        title: 'Error Adding Booking',
        description: error.message,
        variant: 'destructive'
      });
      await trpcUtils.Booking.invalidate();
    }
  });
  const updateCalendarBooking = trpc.Booking.UpdateBooking.useMutation({
    onSuccess: async () => {
      toast({
        title: 'Booking Updated Successfully',
        description: 'Successfully updated booking'
      });
      await trpcUtils.Booking.invalidate();
      if (props.parentShow) {
        props.parentShow(false);
      }
    },
    onError: async error => {
      toast({
        title: 'Error Updating Booking',
        description: error.message,
        variant: 'destructive'
      });
      await trpcUtils.Booking.invalidate();
    }
  });
  const calendarBookingFormSchema = z.object({
    date: z.date(),
    bookingStart: z.date(),
    bookingEnd: z.date(),
    comment: z.string().optional(),
    userId: z.string()
  });
  const calendarBookingForm = useForm<z.infer<typeof calendarBookingFormSchema>>({
    resolver: zodResolver(calendarBookingFormSchema),
    defaultValues: {
      date: props.date || undefined,
      bookingStart: props.bookingStart || undefined,
      bookingEnd: props.bookingEnd || undefined,
      userId: props.userId,
      comment: props.comment || undefined
    }
  });
  const calendarBookingFormHandler = (values: z.infer<typeof calendarBookingFormSchema>) => {
    const bookingDate = new Date(values.date.toDateString());
    const startHours = values.bookingStart.getUTCHours();
    const startMins = values.bookingStart.getUTCMinutes();
    const endHours = values.bookingEnd.getUTCHours();
    const endMins = values.bookingEnd.getUTCMinutes();
    const bookingStart = new Date(bookingDate.setHours(startHours, startMins));
    const bookingEnd = new Date(bookingDate.setHours(endHours, endMins));
    if (props.bookingId) {
      updateCalendarBooking.mutate({
        bookingId: props.bookingId,
        date: values.date,
        bookingStart: bookingStart,
        bookingEnd: bookingEnd,
        comment: values.comment || '',
        userId: values.userId
      });
    } else {
      addCalendarBooking.mutate({
        date: values.date,
        bookingStart: bookingStart,
        bookingEnd: bookingEnd,
        comment: values.comment || '',
        userId: values.userId
      });
    }
  };
  const {
    watch
  } = calendarBookingForm;
  const dateWatcher = watch('date');
  return <Form {...calendarBookingForm} data-sentry-element="Form" data-sentry-component="CalendarBookingForm" data-sentry-source-file="calendarBookingForm.tsx">
      <form onSubmit={calendarBookingForm.handleSubmit(calendarBookingFormHandler)} onReset={() => calendarBookingForm.reset()}>
        <div className='grid grid-cols-1 gap-3 py-4'>
          <FormField control={calendarBookingForm.control} name='date' render={({
          field
        }) => {
          return <FormItem>
                  <FormLabel>Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button variant={'outline'} className={cn('w-[240px] pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}>
                          {field.value ? format(field.value, 'PPP') : <span>Pick a date</span>}
                          <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className='w-auto p-0' align='start'>
                      <CalendarPicker mode='single' selected={field.value} onSelect={field.onChange} />
                    </PopoverContent>
                  </Popover>
                </FormItem>;
        }} data-sentry-element="FormField" data-sentry-source-file="calendarBookingForm.tsx" />
          <FormField control={calendarBookingForm.control} name='bookingStart' render={({
          field
        }) => <FormItem>
                <FormLabel>Start</FormLabel>
                <FormControl>
                  <Input type='time' value={field.value?.toLocaleTimeString([], {
              hourCycle: 'h23',
              hour: '2-digit',
              minute: '2-digit'
            })} onChange={selectedTime => {
              const bookingStart = new Date(dateWatcher.toDateString());
              bookingStart.setHours(parseInt(selectedTime.target.value.split(':')[0]), parseInt(selectedTime.target.value.split(':')[1]), 0);
              field.onChange(bookingStart);
            }} />
                </FormControl>
                <FormMessage />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="calendarBookingForm.tsx" />
          <FormField control={calendarBookingForm.control} name='bookingEnd' render={({
          field
        }) => <FormItem>
                <FormLabel>End</FormLabel>
                <FormControl>
                  <Input type='time' value={field.value?.toLocaleTimeString([], {
              hourCycle: 'h23',
              hour: '2-digit',
              minute: '2-digit'
            })} onChange={selectedTime => {
              const bookingEnd = new Date(dateWatcher.toDateString());
              bookingEnd.setHours(parseInt(selectedTime.target.value.split(':')[0]), parseInt(selectedTime.target.value.split(':')[1]), 0);
              field.onChange(bookingEnd);
            }} />
                </FormControl>
                <FormMessage />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="calendarBookingForm.tsx" />
          <FormField control={calendarBookingForm.control} name='comment' render={({
          field
        }) => <FormItem>
                <FormLabel>Comment?</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="calendarBookingForm.tsx" />
          <FormField control={calendarBookingForm.control} name='userId' render={({
          field
        }) => <FormItem>
                <FormLabel>Member</FormLabel>
                <FormControl>
                  <UserPicker selectedUser={field.value} selectedUserChanged={field.onChange} />
                </FormControl>
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="calendarBookingForm.tsx" />
        </div>
        <br />
        <FormMessage data-sentry-element="FormMessage" data-sentry-source-file="calendarBookingForm.tsx" />
        <div className='space-x-3'>
          <Button type='submit' data-sentry-element="Button" data-sentry-source-file="calendarBookingForm.tsx">Save Booking</Button>
          <Button type='reset' variant={'destructive'} data-sentry-element="Button" data-sentry-source-file="calendarBookingForm.tsx">
            Clear
          </Button>
        </div>
      </form>
    </Form>;
}